import { computed, ref, watch } from "vue";
import { DateTime } from "luxon";
import { typeDateIncrement } from "@/utils/enums/typeDateIncrement";

export function useFullCalendar(
  getEvents,
  getResources,
  isWithResources = false,
) {
  const calendarLoaded = ref(false);
  const calendarRefForWrapper = ref(null);
  const calendarRef = computed(() => {
    return calendarRefForWrapper.value?.fullCalendarRef;
  });
  const currentDate = ref(DateTime.now().toISODate());
  const countMonthsIncrement = ref(1);
  const typeIncrement = ref(typeDateIncrement.MONTHS);
  const events = ref([]);
  const resources = ref([]);
  watch(
    resources,
    (val) => {
      if (val?.length && !isWithResources) {
        calendarRef.value?.getApi().refetchEvents();
      }
    },
    { immediate: true },
  );

  const resourcesOptions = ref(
    isWithResources
      ? {
          resourceOrder: "rank",
          resources: async function (fetchInfo, successCallback) {
            resources.value = await getResources();
            successCallback(resources.value);
          },
          resourcesSet: async function () {
            if (resources.value.length) {
              calendarRef.value.getApi().refetchEvents();
            }
          },
        }
      : {},
  );
  const defaultCalendarOptions = ref({
    loading: (calendarLoading) => {
      calendarLoaded.value = calendarLoading;
    },
    timeZone: "UTC",
    schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
    firstDay: 1,
    buttonText: {
      today: "Сьогодні",
      month: "Місяць",
      year: "Рік",
      day: "День",
      list: "Список",
    },
    locale: "uk",
    nowIndicator: true,
    lazyFetching: false,
    ...resourcesOptions.value,
    events: async function (fetchInfo, successCallback) {
      const dateFrom = DateTime.fromISO(fetchInfo.startStr).toISODate();
      const dateTo = DateTime.fromISO(fetchInfo.endStr).toISODate();
      events.value = await getEvents(resources.value, { dateFrom, dateTo });
      successCallback(events.value);
    },
    slotLaneClassNames: ["slot-highlight"],
    eventClassNames: ["eventDefaultStyle"],
  });

  function setTypeIncrement(type) {
    typeIncrement.value = type;
  }

  function updateCalendar() {
    calendarRef.value?.getApi().refetchEvents();
  }

  return {
    events,
    resources,
    defaultCalendarOptions,
    calendarRefForWrapper,
    countMonthsIncrement,
    calendarLoaded,
    calendarRef,
    currentDate,
    typeIncrement,
    updateCalendar,
    setTypeIncrement,
  };
}

// headerToolbar: {
//   start: "title",
//   end: "today prev,next",
// },
// weekends: true,
// contentHeight: "auto",
// stickyHeaderDates: true,
// customButtons: {
//   today: {
//     text: "Сьогодні",
//     click: () => {
//       currentDate.value = DateTime.now().toISODate();
//       calendarRef.value.getApi().today();
//     },
//   },
//   next: {
//     text: "NEXT",
//     click: () => {
//       currentDate.value = DateTime.fromISO(currentDate.value)
//         .plus({ [typeIncrement.value]: countMonthsIncrement.value })
//         .toISODate();
//       calendarRef.value.getApi().incrementDate({
//         [typeIncrement.value]: Number(countMonthsIncrement.value),
//       });
//     },
//   },
//   prev: {
//     text: "PREV",
//     click: () => {
//       currentDate.value = DateTime.fromISO(currentDate.value)
//         .minus({ [typeIncrement.value]: countMonthsIncrement.value })
//         .toISODate();
//       calendarRef.value.getApi().incrementDate({
//         [typeIncrement.value]: -Number(countMonthsIncrement.value),
//       });
//     },
//   },
// },
