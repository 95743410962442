<script setup>
import FullCalendar from "@fullcalendar/vue3";
import SharedSpinner from "@/views/shared/Spinner.vue";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import { computed, ref } from "vue";

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  calendarLoaded: {
    type: Boolean,
    default: false,
  },
  isSmallOnMobile: {
    type: Boolean,
    default: false,
  },
});

const optionsForCalendar = computed(() => {
  return props.options;
});
const fullCalendarRef = ref(null);

defineExpose({
  fullCalendarRef,
});
const { isMobile } = useBreakpoints();
</script>

<template>
  <div class="calendar">
    <SharedSpinner
      class="calendar__spinner"
      v-show="calendarLoaded"
      :variant="'small'"
    ></SharedSpinner>
    <div
      class="calendar__inner"
      :class="{
        loaded: calendarLoaded,
        small: isMobile && isSmallOnMobile,
      }"
    >
      <FullCalendar
        class="demo-app-calendar"
        ref="fullCalendarRef"
        :options="optionsForCalendar"
      >
        <template v-slot:eventContent="arg">
          <slot :arg="arg" name="calendar-content"></slot>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar {
  position: relative;
  height: 100%;

  .demo-app-calendar {
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }
}

.calendar__inner.loaded {
  pointer-events: none;
  opacity: 0.4;
}

.calendar__inner {
  background: #fff;
}

.calendar__inner.small ::v-deep(.fc-timeline-event) {
  padding: 5px;
}

.calendar__inner.small {
  @media (max-width: 768px) {
    & ::v-deep(a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner) {
      font-size: 8px;
    }

    & ::v-deep(.fc-timeline-event p) {
      font-size: 8px !important;
    }

    & ::v-deep(span.fc-datagrid-cell-main) {
      font-size: 8px;
    }
  }
}

.calendar__spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

::v-deep() {
  .fc-timeline-slot-lane.fc-day.fc-day-sun,
  .fc-timeline-slot-lane.fc-day.fc-day-sat {
    background-color: rgba(255, 220, 40, 0.15);
  }

  .fc-timeline .fc-resource-timeline-divider.fc-cell-shaded {
    pointer-events: none;
  }

  .fc-timeline-slot-label.fc-day.fc-day-sun,
  .fc-timeline-slot-label.fc-day.fc-day-sat {
    background-color: rgba(255, 220, 40, 0.15);
  }

  .fc .fc-scrollgrid-section table {
    height: 100%;
  }

  .fc-timeline-header-row:first-child .fc-timeline-slot-label {
    background: transparent !important;
  }

  .fc-resource .fc-datagrid-cell-cushion {
    padding-left: 0;
  }

  .fc-datagrid-cell .fc-datagrid-cell-cushion {
    padding-left: 0;
  }

  span.fc-datagrid-cell-main {
    font-size: 12px;
  }

  a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
    font-size: 12px;
  }

  .fc-timeline-event {
    pointer-events: none;
    background: transparent;
    padding: 10px;
  }

  .fc-timeline-event-harness {
    pointer-events: none;
    background: transparent;
  }

  .fc .fc-timeline-events {
    pointer-events: none;
  }

  .fc-timeline-event p {
    color: #84889e;
    font-size: 12px;
    background: transparent;
    margin-bottom: 0;
    line-height: 15px;
  }

  .fc-event:has(.fc-event-main .empty-event) {
    display: none;
  }

  .fc .fc-daygrid-day-top {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .fc .fc-button {
    padding-top: 0.25rem;
    box-shadow:
      0 4px 7px -1px rgba(0, 0, 0, 0.11),
      0 2px 4px -1px rgba(0, 0, 0, 0.07);
    transition: all 0.15s ease-in;
  }

  .fc .fc-button,
  .fc .fc-button .fc-icon {
    font-size: 0.875rem;
  }

  .fc .fc-button-primary {
    background-color: #387459;
    border-color: #387459;
  }

  .fc .fc-button-primary:focus,
  .fc .fc-button-primary:hover,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled):active:focus {
    transform: none;
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.09),
      0 2px 3px -1px rgba(0, 0, 0, 0.07);
    background-color: #387459;
    border-color: #387459;
  }
}
</style>
