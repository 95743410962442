<template>
  <div class="container-fluid my-3 py-3 staff-page" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluidpy-3 staff-page" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-12">
        <div class="row mt-3">
          <div class="available">
            <div class="element">Доступність</div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="staffTemplate.isActive"
              />
            </div>
          </div>
        </div>
        <!-- Card Basic Info -->
        <div
          class="nav-wrapper position-relative end-0 mt-2 mb-2"
          v-if="company.crmMode !== 'hotel'"
        >
          <ul class="nav nav-pills nav-fill p-1" role="tablist">
            <li class="nav-item">
              <a
                id="nav-main-tab"
                class="nav-link mb-0 px-0 py-1 active"
                data-bs-toggle="tab"
                href="#nav-main"
                role="tab"
                aria-controls="main"
                aria-selected="true"
              >
                Головна
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link mb-0 px-0 py-1"
                data-bs-toggle="tab"
                href="#nav-access"
                role="tab"
                aria-controls="access"
                aria-selected="false"
              >
                Доступ
              </a>
            </li>
          </ul>
        </div>
        <div class="card" id="basic-info">
          <div class="card-body pt-0 mx-3">
            <div class="row mt-3">
              <div class="col-lg-3">
                <h3 v-if="isMobile">Загальна інформація</h3>
                <h6 v-if="isDesktop">Загальна інформація</h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-6 mt-2">
                    <div
                      class="input-group input-group-outline"
                      :class="{ 'is-filled': staffTemplate.name }"
                    >
                      <label
                        class="form-label"
                        v-if="company.crmMode === 'hotel'"
                        >Назва</label
                      >
                      <label
                        class="form-label"
                        v-if="company.crmMode !== 'hotel'"
                        >Імʼя</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="staffTemplate.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade active show"
                id="nav-main"
                role="tabpanel"
                aria-labelledby="nav-main-tab"
              >
                <div class="card-body p-1">
                  <hr />
                  <div class="row mt-2" v-show="dataLoaded">
                    <div class="col-lg-3">
                      <h3 v-if="company.crmMode === 'hotel' && isMobile">
                        Категорія
                      </h3>
                      <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                        Категорія
                      </h6>

                      <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                        Категорія
                      </h3>
                      <h6 v-if="company.crmMode !== 'hotel' && isDesktop">
                        Категорія
                      </h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div class="col-lg-3">
                          <select
                            class="form-control"
                            name="choices-positions"
                            id="choices-positions"
                            v-model="staffTemplate.positionId"
                          ></select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mt-2"
                    v-show="dataLoaded"
                    v-if="company.crmMode === 'hotel'"
                  >
                    <div class="col-lg-3">
                      <h3 v-if="company.crmMode === 'hotel' && isMobile">
                        Правила перебування
                      </h3>
                      <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                        Правила перебування
                      </h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row mb-2">
                        <div class="col-lg-4 icon-label">
                          <img
                            src="@/assets/icons/GuestsIcon.png"
                            width="24"
                            height="24"
                            alt="Guests"
                          />
                          Максимальна кількість гостей<br />(включаючи дітей)
                        </div>

                        <div class="col-lg-3">
                          <div class="input-group input-group-outline">
                            <select
                              class="form-control text-center"
                              v-model="staffTemplate.checkinRules.guestsMax"
                            >
                              <option
                                v-for="(option, index) in guestsMaxOptions"
                                :key="index"
                                :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 icon-label">
                          <img
                            src="@/assets/icons/TimeIcon.png"
                            width="24"
                            height="24"
                            alt="time"
                          />
                          Час заїзду (з - до)
                        </div>
                        <div class="col-lg-3">
                          <div class="row">
                            <div class="col-lg-6 mt-1">
                              <div class="input-group input-group-outline">
                                <input
                                  type="time"
                                  class="form-control"
                                  v-model="
                                    staffTemplate.checkinRules.checkinTime.from
                                  "
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 mt-1">
                              <div class="input-group input-group-outline">
                                <input
                                  type="time"
                                  class="form-control"
                                  v-model="
                                    staffTemplate.checkinRules.checkinTime.to
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-4 icon-label">
                          <img
                            src="@/assets/icons/TimeIcon.png"
                            width="24"
                            height="24"
                            alt="time"
                          />
                          Час виїзду
                        </div>
                        <div class="col-lg-3">
                          <div class="input-group input-group-outline">
                            <input
                              type="time"
                              class="form-control"
                              v-model="staffTemplate.checkinRules.checkoutTime"
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row mt-2">
                        <div class="col-lg-4 icon-label">
                          <img
                            src="@/assets/icons/PetsIcon.png"
                            width="24"
                            height="24"
                            alt="Pets friendly"
                          />
                          Pets-friendly
                        </div>
                        <div class="col-lg-4">
                          <div class="customSwitcher">
                            <div
                              class="element input-group input-group-outline"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Опис"
                                v-model="
                                  staffTemplate.checkinRules.petsFriendly.text
                                "
                              />
                            </div>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="
                                  staffTemplate.checkinRules.petsFriendly
                                    .enabled
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row mt-2">
                        <div class="col-lg-4 icon-label">
                          <img
                            src="@/assets/icons/CheckInIcon.png"
                            width="24"
                            height="24"
                            alt="Self check-in"
                          />
                          Самостійне заселення
                        </div>
                        <div class="col-lg-4">
                          <div class="customSwitcher">
                            <div
                              class="element input-group input-group-outline"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Опис"
                                v-model="
                                  staffTemplate.checkinRules.smartCheckin.text
                                "
                              />
                            </div>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="
                                  staffTemplate.checkinRules.smartCheckin
                                    .enabled
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row mt-2 accessCode"
                        v-show="staffTemplate.checkinRules.smartCheckin.enabled"
                      >
                        <div class="col-lg-4 icon-label">
                          Зазначте тут код доступу, який ви зможете відправляти
                          в СМС гостям
                        </div>
                        <div class="col-lg-4">
                          <div class="input-group input-group-outline">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Код доступу"
                              v-model="
                                staffTemplate.checkinRules.smartCheckin.code
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row mt-2">
                        <div class="col-lg-4 icon-label">
                          <img
                            src="@/assets/icons/KidsIcon.png"
                            width="24"
                            height="24"
                            alt="Self check-in"
                          />
                          Діти безкоштовно
                        </div>
                        <div class="col-lg-4">
                          <div class="customSwitcher">
                            <div
                              class="element input-group input-group-outline"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Діти віком до 12 років - безкоштовно"
                                v-model="
                                  staffTemplate.checkinRules.children.text
                                "
                              />
                            </div>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="
                                  staffTemplate.checkinRules.children.enabled
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Card Leaving room Photos  -->
                  <!-- <div
                    class="row mt-2"
                    v-if="company.crmMode === 'hotel' && showDevFeatures"
                  >
                    <div class="col-lg-3">
                      <h6>DEV Фото вітальні</h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="multisteps-form__content">
                            <div class="row">
                              <div class="col-12">
                                <label class="form-control mb-0"
                                  >Додайте фото натиснувши на область нижче або
                                  просто перетягніть сюди файли
                                </label>
                                <div
                                  class="form-control border dropzone"
                                  id="staffPhotos"
                                ></div>
                              </div>
                              <div v-if="staffTemplate?.photos?.length">
                                <div class="col-12">
                                  <a
                                    class="nav-link text-danger d-flex c-width-200"
                                    data-scroll=""
                                    href="#"
                                  >
                                    <i class="material-icons text-lg me-2"
                                      >delete</i
                                    >
                                    <span class="text-sm">Видалити</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- Card Leaving room Photos finish  -->

                  <!-- Card Kitchen Photos  -->
                  <!-- <div
                    class="row mt-2"
                    v-if="company.crmMode === 'hotel' && showDevFeatures"
                  >
                    <div class="col-lg-3">
                      <h6>DEV Фото кухні</h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="multisteps-form__content">
                            <div class="row">
                              <div class="col-12">
                                <label class="form-control mb-0"
                                  >Додайте фото натиснувши на область нижче або
                                  просто перетягніть сюди файли
                                </label>
                                <div
                                  class="form-control border dropzone"
                                  id="staffPhotos"
                                ></div>
                              </div>
                              <div v-if="staffTemplate?.photos?.length">
                                <div class="col-12">
                                  <a
                                    class="nav-link text-danger d-flex c-width-200"
                                    data-scroll=""
                                    href="#"
                                  >
                                    <i class="material-icons text-lg me-2"
                                      >delete</i
                                    >
                                    <span class="text-sm">Видалити</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- Card Kitchen Photos finish  -->

                  <!-- Card sleeping room Photos  -->
                  <!-- <div
                    class="row mt-2"
                    v-if="company.crmMode === 'hotel' && showDevFeatures"
                  >
                    <div class="col-lg-3">
                      <h6>DEV Фото спальні</h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="multisteps-form__content">
                            <div class="row">
                              <div class="col-12">
                                <label class="form-control mb-0"
                                  >Додайте фото натиснувши на область нижче або
                                  просто перетягніть сюди файли
                                </label>
                                <div
                                  class="form-control border dropzone"
                                  id="staffPhotos"
                                ></div>
                              </div>
                              <div v-if="staffTemplate?.photos?.length">
                                <div class="col-12">
                                  <a
                                    class="nav-link text-danger d-flex c-width-200"
                                    data-scroll=""
                                    href="#"
                                  >
                                    <i class="material-icons text-lg me-2"
                                      >delete</i
                                    >
                                    <span class="text-sm">Видалити</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- Card sleeping room Photos finish  -->

                  <!-- Card rest room Photos  -->
                  <!-- <div
                    class="row mt-2"
                    v-if="company.crmMode === 'hotel' && showDevFeatures"
                  >
                    <div class="col-lg-3">
                      <h6>DEV Фото ванної кімнати</h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="multisteps-form__content">
                            <div class="row">
                              <div class="col-12">
                                <label class="form-control mb-0"
                                  >Додайте фото натиснувши на область нижче або
                                  просто перетягніть сюди файли
                                </label>
                                <div
                                  class="form-control border dropzone"
                                  id="staffPhotos"
                                ></div>
                              </div>
                              <div v-if="staffTemplate?.photos?.length">
                                <div class="col-12">
                                  <a
                                    class="nav-link text-danger d-flex c-width-200"
                                    data-scroll=""
                                    href="#"
                                  >
                                    <i class="material-icons text-lg me-2"
                                      >delete</i
                                    >
                                    <span class="text-sm">Видалити</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- Card rest room Photos finish  -->

                  <!-- Card Yard Photos  -->
                  <!-- <div
                    class="row mt-2"
                    v-if="company.crmMode === 'hotel' && showDevFeatures"
                  >
                    <div class="col-lg-3">
                      <h6>DEV Фото подвірʼя</h6>
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="multisteps-form__content">
                            <div class="row">
                              <div class="col-12">
                                <label class="form-control mb-0"
                                  >Додайте фото натиснувши на область нижче або
                                  просто перетягніть сюди файли
                                </label>
                                <div
                                  class="form-control border dropzone"
                                  id="staffPhotos"
                                ></div>
                              </div>
                              <div v-if="staffTemplate?.photos?.length">
                                <div class="col-12">
                                  <a
                                    class="nav-link text-danger d-flex c-width-200"
                                    data-scroll=""
                                    href="#"
                                  >
                                    <i class="material-icons text-lg me-2"
                                      >delete</i
                                    >
                                    <span class="text-sm">Видалити</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- Card Yard Photos finish  -->

                  <!-- additional services  -->
                  <div class="row mt-2" v-if="this.mode === 'edit'">
                    <hr />
                    <div class="col-lg-3">
                      <h3 v-if="isMobile">Додаткові послуги</h3>
                      <h6 v-if="isDesktop">Додаткові послуги</h6>
                    </div>
                    <div class="col-lg-9">
                      <label
                        >Перелік додаткових послуг, які доступні в цьому
                        обʼєкті</label
                      >
                      <div
                        class="table-responsive"
                        v-if="additionalServices?.length"
                      >
                        <table class="table table-flush" id="products-list">
                          <thead class="thead-light">
                            <tr>
                              <th class="text-center text-xs">Назва</th>
                              <th class="text-center text-xs">Вартість</th>
                              <th
                                class="text-center text-xs"
                                v-if="
                                  branch.paymentMonobankEnabled ||
                                  branch.paymentLiqPayEnabled
                                "
                              >
                                Передоплата
                              </th>
                              <th
                                class="text-center text-xs"
                                v-if="company.crmMode === 'hotel'"
                              >
                                Ціна подобова
                              </th>
                              <th
                                class="text-center text-xs"
                                v-if="company.crmMode === 'hotel'"
                              >
                                Ціна за гостя
                              </th>
                              <th class="text-center text-xs">
                                Обрана за замовчуванням
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(
                                additionalService, index
                              ) in additionalServiceRules"
                              :key="index"
                            >
                              <td class="text-center text-xs">
                                <span>{{ additionalService.name }}</span>
                              </td>
                              <td class="text-center text-xs">
                                <span v-if="!additionalService.isPricePerGuest"
                                  >{{ additionalService.price }} грн</span
                                >
                                <div v-if="additionalService.isPricePerGuest">
                                  <div
                                    v-for="(
                                      item, idx
                                    ) of additionalService.pricesPerGuest"
                                    v-bind:key="idx"
                                  >
                                    <span>
                                      {{ item.guests }} - {{ item.price }} грн
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td
                                class="text-center text-xs"
                                v-if="
                                  branch.paymentMonobankEnabled ||
                                  branch.paymentLiqPayEnabled
                                "
                              >
                                <span v-if="!additionalService.isPricePerGuest">
                                  {{
                                    getAdditionalServicePrepaymentAmount(
                                      additionalService,
                                    )
                                  }}
                                  грн
                                </span>
                                <div v-if="additionalService.isPricePerGuest">
                                  <div
                                    v-for="(
                                      item, idx
                                    ) of getAdditionalServicePrepaymentAmountPerGuest(
                                      additionalService,
                                    )"
                                    v-bind:key="idx"
                                  >
                                    <span>
                                      {{ item.guests }} -
                                      {{ item.prepaymentAmount }} грн
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td
                                class="text-center text-xs"
                                v-if="company.crmMode === 'hotel'"
                              >
                                <span>{{
                                  additionalService.isPricePerDay ? "так" : "ні"
                                }}</span>
                              </td>
                              <td
                                class="text-center text-xs"
                                v-if="company.crmMode === 'hotel'"
                              >
                                <span>{{
                                  additionalService.isPricePerGuest
                                    ? "так"
                                    : "ні"
                                }}</span>
                              </td>
                              <td class="text-center text-xs">
                                <span>{{
                                  additionalService.isPreselected ? "так" : "ні"
                                }}</span>
                              </td>
                            </tr>
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- additional services finish  -->
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="nav-access"
                role="tabpanel"
                aria-labelledby="nav-access-tab"
              >
                <div class="card-body p-1">
                  <h5 class="mt-2">Доступ в адмін панель</h5>
                  <div class="row mt-3">
                    <div class="col-lg-3">
                      <h6>Телефон</h6>
                      <label class="text-xs"
                        >Буде використано для доступу в особистий кабінет</label
                      >
                    </div>
                    <div class="col-lg-9 mb-2">
                      <div class="row">
                        <div
                          class="col-lg-3 mt-2"
                          v-show="company.crmMode !== 'hotel'"
                        >
                          <div
                            class="input-group input-group-outline"
                            :class="{ 'is-filled': staffTemplate.phoneNumber }"
                          >
                            <label class="form-label"
                              >Телефон (+380671234567)</label
                            >
                            <input
                              type="tel"
                              class="form-control"
                              minlength="13"
                              maxlength="13"
                              pattern="\+[0-9]{12}"
                              v-model="staffTemplate.phoneNumber"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="isOwner" />
                  <div class="row mt-3" v-if="isOwner">
                    <div class="col-lg-3">
                      <h6>Пароль</h6>
                      <label class="text-xs"
                        >Буде використано для доступу в особистий кабінет</label
                      >
                    </div>
                    <div class="col-lg-9 mb-2">
                      <!-- Password -->
                      <p
                        class="text-sm mb-0"
                        v-if="mode === 'edit' && !phoneNumberExists"
                      >
                        Щоб встановити пароль Майстру, спочатку додайте йому
                        номер телефону і збережіть зміни. Після збереження
                        зайдіть сюди знову і ви зможете встановити пароль.
                      </p>
                      <div
                        id="reset-password"
                        v-if="phoneNumberExists && isOwner"
                      >
                        <div class="">
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div
                                class="input-group input-group-outline"
                                :class="{ 'is-filled': password1 }"
                              >
                                <label class="form-label">Введіть пароль</label>
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder=""
                                  v-model="password1"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div
                                class="input-group input-group-outline"
                                :class="{ 'is-filled': password2 }"
                              >
                                <label class="form-label"
                                  >Повторіть пароль</label
                                >
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder=""
                                  v-model="password2"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 d-flex" v-if="dataLoaded">
                            <div class="d-flex align-items-center mb-sm-0 mt-4">
                              <div class="w-50"></div>
                              <div class="w-50 text-end">
                                <button
                                  class="btn btn-success"
                                  role="button"
                                  @click="resetPassword"
                                  :disabled="!isValidPassword()"
                                >
                                  Встановити новий пароль
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Password finish -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Basic Info finish -->

        <!-- Online booking module -->
        <div class="card mt-2" id="basic-info">
          <div class="card-header">
            <h2 v-if="isMobile">Онлайн бронювання</h2>
            <h5 v-if="isDesktop">Онлайн бронювання</h5>
            Тут зібрані всі налаштування, які будуть відображатись на сторінці
            модулю онлайн бронювання
            <hr />
            <div class="row mt-2" v-show="dataLoaded">
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  Онлайн бронювання
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  Онлайн бронювання
                </h6>

                <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                  Онлайн запис бронювання
                </h3>
                <h6 v-if="company.crmMode !== 'hotel' && isDesktop">
                  Онлайн запис
                </h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-check form-switch mt-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="staffTemplate.onlineBooking"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <!-- Card Description -->
            <div class="row mt-2">
              <div class="col-lg-3">
                <h3 v-if="isMobile">Детальний опис</h3>
                <h6 v-if="isDesktop">Детальний опис</h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-group input-group-outline">
                      <textarea
                        class="form-control"
                        style="height: 75px"
                        placeholder=""
                        v-model="staffTemplate.description"
                        rows="3"
                        ref="description"
                        @input="autoResizeTextarea"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <!-- Card Description finish  -->

            <!-- Card Photo  -->
            <div class="row mt-2">
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  Головне фото
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  Головне фото
                </h6>

                <h3 v-if="company.crmMode !== 'hotel' && isMobile">Фото</h3>
                <h6 v-if="company.crmMode !== 'hotel' && isDesktop">Фото</h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="d-flex align-items-center gap-2 infoBlock">
                      <span class="material-symbols-outlined"> info </span>
                      <label style="color: #458466">
                        Вимоги до зображень:<br />
                        1. Розмір файлу не більше ніж 4mb<br />
                        2. Формат .gif, .jpeg, .jpg або .png
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="multisteps-form__content">
                      <div class="row">
                        <div
                          class="col-12"
                          v-show="
                            !staffTemplate.photo.key && !staffTemplate.photo.url
                          "
                        >
                          <label class="form-control mb-0"
                            >Додайте фото натиснувши на область нижче або просто
                            перетягніть сюди файл
                          </label>
                          <div
                            class="form-control border dropzone"
                            id="staffPhoto"
                          ></div>
                        </div>
                        <div
                          class="col-12"
                          v-show="
                            staffTemplate.photo.key && staffTemplate.photo.url
                          "
                        >
                          <a target="_blank" :href="staffTemplate.photo.url">
                            <img
                              alt="staff photo"
                              :src="staffTemplate.photo.url"
                              width="200"
                            />
                          </a>
                          <a
                            class="nav-link text-danger d-flex c-width-200"
                            data-scroll=""
                            href="#"
                          >
                            <i class="material-icons text-lg me-2">delete</i>
                            <span class="text-sm" @click="removePhoto"
                              >Видалити</span
                            >
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <!-- Card Photo finish  -->

            <!-- Card Additional Photos  -->
            <div class="row mt-2" v-show="mode === 'edit'">
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  Додаткові фото
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  Додаткові фото
                </h6>

                <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                  Фото робот
                </h3>
                <h6 v-if="company.crmMode !== 'hotel' && isDesktop">
                  Фото робот
                </h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="d-flex align-items-center gap-2 infoBlock">
                      <span class="material-symbols-outlined"> info </span>
                      <label style="color: #458466">
                        Вимоги до зображень:<br />
                        1. Розмір файлу не більше ніж 4mb<br />
                        2. Формат .gif, .jpeg, .jpg або .png
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="multisteps-form__content">
                      <div class="row">
                        <div class="col-12">
                          <label class="form-control mb-0"
                            >Додайте фото натиснувши на область нижче або просто
                            перетягніть сюди файли
                          </label>
                          <div
                            class="form-control border dropzone"
                            id="staffPhotos"
                          ></div>
                        </div>

                        <Photos
                          v-if="staffTemplate?.photos?.length"
                          v-model="staffTemplate.photos"
                          class="mt-2"
                          @remove="(photo) => removePhotoAdditional(photo._id)"
                          @update="(photos) => updatePhotoAdditional(photos)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <!-- Card Additional Photos finish  -->
            <!-- Адреса -->
            <div
              class="row mt-3"
              v-if="showDevFeatures && company.crmMode === 'hotel'"
            >
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  DEV Адреса
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  DEV Адреса
                </h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="input-group input-group-outline"
                      :class="{ 'is-filled': staffTemplate.address }"
                    >
                      <label class="form-label">Адреса</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="staffTemplate.address"
                      />
                    </div>
                    <label
                      >(текстовая адреса, яка буде відображатись на сторінці
                      онлайн бронювання)</label
                    >
                  </div>
                  <div class="col-lg-6">
                    <div
                      class="input-group input-group-outline"
                      :class="{ 'is-filled': staffTemplate.googleMaps }"
                    >
                      <label class="form-label">Посиланна на Google maps</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="staffTemplate.googleMaps"
                      />
                    </div>
                    <label
                      >(текстовая адреса, яка буде відображатись на сторінці
                      онлайн бронювання)</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="input-group input-group-outline"
                      :class="{
                        'is-filled': staffTemplate.iFrameAddress,
                      }"
                    >
                      <label class="form-label">Google maps iFrame</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="staffTemplate.iFrameAddress"
                      />
                    </div>
                    <label
                      >(додайте код iFrame з Google maps, щоб мапа з позначкою
                      відобразилась на сторінці онлайн бронювання)</label
                    >
                  </div>
                  <div class="col-lg-6">
                    тут показати превью айфрейму як ми це робимо для філії
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <!-- finish Адреса -->

            <!-- Правила безпеки -->
            <div
              class="row mt-2"
              v-show="dataLoaded"
              v-if="company.crmMode === 'hotel'"
            >
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  Правила безпеки
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  Правила безпеки
                </h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div
                  v-for="(amenity, idx) in amenities.security"
                  :key="amenity.text"
                >
                  <div class="row">
                    <div class="col-lg-4 icon-label">
                      <img
                        :src="require(`@/assets/icons/${amenity.iconName}.png`)"
                        width="24"
                        height="24"
                        :alt="amenity.iconAlt"
                      />
                      {{ amenity.text }}
                    </div>
                    <div class="col-lg-4">
                      <div class="customSwitcher">
                        <div class="element input-group input-group-outline">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Опис"
                            v-model="amenity.description"
                          />
                        </div>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="amenity.enabled"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="idx !== amenities.security.length - 1" />
                </div>
              </div>
              <hr />
            </div>
            <!-- Правила безпеки finish-->

            <!-- Політика скасувань -->
            <div
              class="row mt-2"
              v-show="dataLoaded"
              v-if="company.crmMode === 'hotel'"
            >
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  Політика скасувань
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  Політика скасувань
                </h6>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="customSwitcherFeatured">
                      <div class="element form-check-label mt-1">
                        Дозволити повернення
                      </div>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input mt-1"
                          type="checkbox"
                          v-model="staffTemplate.cancellationPolicy.enabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-12 mb-2 mt-3"
                  v-if="staffTemplate.cancellationPolicy.enabled"
                >
                  <div class="table-responsive">
                    <table class="table table-flush" id="products-list">
                      <thead class="thead-light">
                        <tr>
                          <th class="text-center text-xs">Правило</th>
                          <th class="text-center text-xs">Днів</th>
                          <th class="text-center text-xs">% повернення</th>
                          <th class="text-center text-xs">Стан</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center text-xs">
                            <span
                              >Коли до дати заселення <b>більше</b> ніж</span
                            >
                          </td>
                          <td class="text-center text-xs">
                            <div class="input-group input-group-outline">
                              <select
                                class="form-control text-center text-xxs"
                                v-model="
                                  staffTemplate.cancellationPolicy.rules[0].days
                                "
                              >
                                <option :value="1">1 день</option>
                                <option :value="2">2 дні</option>
                                <option :value="3">3 дні</option>
                                <option :value="4">4 дні</option>
                                <option :value="5">5 днів</option>
                                <option :value="6">6 днів</option>
                                <option :value="7">7 днів</option>
                                <option :value="8">8 днів</option>
                                <option :value="9">9 днів</option>
                                <option :value="10">10 днів</option>
                                <option :value="11">11 днів</option>
                                <option :value="12">12 днів</option>
                                <option :value="13">13 днів</option>
                                <option :value="14">14 днів</option>
                                <option :value="15">15 днів</option>
                                <option :value="16">16 днів</option>
                                <option :value="17">17 днів</option>
                                <option :value="18">18 днів</option>
                                <option :value="19">19 днів</option>
                                <option :value="20">20 днів</option>
                                <option :value="21">21 день</option>
                                <option :value="22">22 дні</option>
                                <option :value="23">23 дні</option>
                                <option :value="24">24 дні</option>
                                <option :value="25">25 днів</option>
                                <option :value="26">26 днів</option>
                                <option :value="27">27 днів</option>
                                <option :value="28">28 днів</option>
                                <option :value="29">29 днів</option>
                                <option :value="30">30 днів</option>
                                <option :value="31">31 день</option>
                              </select>
                            </div>
                          </td>
                          <td class="text-center text-xs">
                            <span> 100% </span>
                          </td>
                          <td>
                            <div class="form-check form-switch mt-2">
                              <input
                                class="form-check-input mt-1 text-center"
                                type="checkbox"
                                v-model="
                                  staffTemplate.cancellationPolicy.rules[0]
                                    .enabled
                                "
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center text-xs">
                            <span
                              >Коли до дати заселення <b>більше</b> ніж</span
                            >
                          </td>
                          <td class="text-center text-xs">
                            <div class="input-group input-group-outline">
                              <select
                                class="form-control text-center text-xxs"
                                v-model="
                                  staffTemplate.cancellationPolicy.rules[1].days
                                "
                              >
                                <option :value="1">1 день</option>
                                <option :value="2">2 дні</option>
                                <option :value="3">3 дні</option>
                                <option :value="4">4 дні</option>
                                <option :value="5">5 днів</option>
                                <option :value="6">6 днів</option>
                                <option :value="7">7 днів</option>
                                <option :value="8">8 днів</option>
                                <option :value="9">9 днів</option>
                                <option :value="10">10 днів</option>
                                <option :value="11">11 днів</option>
                                <option :value="12">12 днів</option>
                                <option :value="13">13 днів</option>
                                <option :value="14">14 днів</option>
                                <option :value="15">15 днів</option>
                                <option :value="16">16 днів</option>
                                <option :value="17">17 днів</option>
                                <option :value="18">18 днів</option>
                                <option :value="19">19 днів</option>
                                <option :value="20">20 днів</option>
                                <option :value="21">21 день</option>
                                <option :value="22">22 дні</option>
                                <option :value="23">23 дні</option>
                                <option :value="24">24 дні</option>
                                <option :value="25">25 днів</option>
                                <option :value="26">26 днів</option>
                                <option :value="27">27 днів</option>
                                <option :value="28">28 днів</option>
                                <option :value="29">29 днів</option>
                                <option :value="30">30 днів</option>
                                <option :value="31">31 день</option>
                              </select>
                            </div>
                          </td>
                          <td class="text-center text-xs">
                            <span> 50% </span>
                          </td>
                          <td>
                            <div class="form-check form-switch mt-2">
                              <input
                                class="form-check-input mt-1"
                                type="checkbox"
                                v-model="
                                  staffTemplate.cancellationPolicy.rules[1]
                                    .enabled
                                "
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center text-xs">
                            <span>Коли до дати заселення <b>менше</b> ніж</span>
                          </td>
                          <td class="text-center text-xs">
                            <div class="input-group input-group-outline">
                              <select
                                class="form-control text-center text-xxs"
                                v-model="
                                  staffTemplate.cancellationPolicy.rules[2].days
                                "
                              >
                                <option :value="1">1 день</option>
                                <option :value="2">2 дні</option>
                                <option :value="3">3 дні</option>
                                <option :value="4">4 дні</option>
                                <option :value="5">5 днів</option>
                                <option :value="6">6 днів</option>
                                <option :value="7">7 днів</option>
                                <option :value="8">8 днів</option>
                                <option :value="9">9 днів</option>
                                <option :value="10">10 днів</option>
                                <option :value="11">11 днів</option>
                                <option :value="12">12 днів</option>
                                <option :value="13">13 днів</option>
                                <option :value="14">14 днів</option>
                                <option :value="15">15 днів</option>
                                <option :value="16">16 днів</option>
                                <option :value="17">17 днів</option>
                                <option :value="18">18 днів</option>
                                <option :value="19">19 днів</option>
                                <option :value="20">20 днів</option>
                                <option :value="21">21 день</option>
                                <option :value="22">22 дні</option>
                                <option :value="23">23 дні</option>
                                <option :value="24">24 дні</option>
                                <option :value="25">25 днів</option>
                                <option :value="26">26 днів</option>
                                <option :value="27">27 днів</option>
                                <option :value="28">28 днів</option>
                                <option :value="29">29 днів</option>
                                <option :value="30">30 днів</option>
                                <option :value="31">31 день</option>
                              </select>
                            </div>
                          </td>
                          <td class="text-center text-xs">
                            <span> Оплата не повертається </span>
                          </td>
                          <td>
                            <div class="form-check form-switch mt-2">
                              <input
                                class="form-check-input mt-1"
                                type="checkbox"
                                v-model="
                                  staffTemplate.cancellationPolicy.rules[2]
                                    .enabled
                                "
                              />
                            </div>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Політика скасувань finish-->
          </div>

          <div class="card-body p-1"></div>
        </div>
        <!-- Online booking module finish -->

        <!-- iCal sync -->
        <div
          class="card mt-2"
          id="basic-info"
          v-if="
            company.isChannelManagerEnabled || company.crmMode === 'general'
          "
        >
          <div class="card-header">
            <div class="row mt-2">
              <div class="col-lg-3">
                <h3 v-if="company.crmMode === 'hotel' && isMobile">
                  Менеджер каналів
                </h3>
                <h6 v-if="company.crmMode === 'hotel' && isDesktop">
                  Менеджер каналів
                </h6>
              </div>
              <div class="col-lg-9">
                <label v-if="company.crmMode === 'hotel'"
                  >Синхронізація даних про бронювання з зовнішніми системи ОТА:
                  Airbnb, booking.com та іншими</label
                >
                <label v-if="company.crmMode !== 'hotel'"
                  >Синхронізація календаря з зовнішніми календарями: Google
                  calendar, Apple calendar та іншими.</label
                >
                <div>
                  <button
                    v-if="company.crmMode === 'hotel'"
                    class="btn btn-icon btn-outline-secondary"
                    type="button"
                    name="button"
                    data-bs-toggle="modal"
                    :data-bs-target="`#${modalParamsImportSyncUrl.modalId}`"
                  >
                    + Імпорт календаря
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-icon btn-outline-secondary"
                    type="button"
                    name="button"
                    data-bs-toggle="modal"
                    :data-bs-target="`#${modalParamsExportSyncUrl.modalId}`"
                  >
                    + Експорт календаря
                  </button>
                </div>

                <div class="table-responsive" v-if="syncUrlsImport?.length">
                  <table class="table table-flush" id="products-list">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center text-xs">Назва</th>
                        <th class="text-center text-xs">Дата створення</th>
                        <th class="text-center text-xs">Дії</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in syncUrlsImport" :key="index">
                        <td class="text-center text-xs">
                          <div>{{ item.name }}</div>
                          <div v-if="item.lastSyncAt">
                            Останнє оновлення:
                            {{ showDateDetails(item.lastSyncAt) }}
                          </div>
                        </td>
                        <td class="text-center text-xs">
                          <span>{{ showDateDetails(item.createdAt) }} </span>
                        </td>
                        <td class="text-center">
                          <div class="col-12 text-center">
                            <router-link
                              to=""
                              class="btn"
                              data-bs-toggle="modal"
                              :data-bs-target="`#${modalParamsRemoveSyncUrl.modalId}`"
                              @click="removeSyncUrl(item._id, item.name)"
                            >
                              <i class="material-icons text-md text-danger"
                                >delete</i
                              >
                            </router-link>
                            <router-link
                              to=""
                              class="btn"
                              data-bs-toggle="modal"
                              :data-bs-target="`#${modalParamsImportSyncUrl.modalId}`"
                              @click="updateSyncUrl(item)"
                            >
                              <i class="fas fa-eye text-secondary"></i>
                            </router-link>
                            <router-link
                              to=""
                              class="btn"
                              :disabled="syncUrlInProgress"
                              @click="syncUrlRefresh(item)"
                            >
                              <i class="fas fa-sync text-secondary"></i>
                            </router-link>
                            <!-- Modal -->
                          </div>
                        </td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body"></div>
        </div>
        <!-- iCal sync finish -->

        <div class="card mt-2" id="tips" v-if="company.crmMode === 'hotel'">
          <div class="card-body mx-3 pt-0">
            <h2 class="mt-3" v-if="isMobile">Зручності</h2>
            <h5 class="mt-3" v-if="isDesktop">Зручності</h5>
            <div class="row infoBlock">
              <div class="d-flex align-items-center gap-2">
                <span class="material-symbols-outlined"> info </span>
                <label style="color: #458466">
                  В цьому розділі ви можете зазначити всі зручності, які
                  доступні для цього обʼекту і вони будуть відображені на
                  сторінці онлайн бронювання цього обʼєкту.<br />
                  Зазначивщи зручність як <b>Featured</b> дозволить їй
                  відображатись в окремому списку згори сторінки.
                </label>
              </div>
            </div>
            <div
              v-for="(amenitiesList, name, amenityIdx) in amenities"
              v-bind:key="name"
            >
              <div v-if="name !== 'security'">
                <div class="row mt-2">
                  <div class="col-lg-3">
                    <h6>{{ amenitiesList[0].sectionName }}</h6>
                  </div>

                  <div class="col-lg-9 mb-2">
                    <div
                      v-for="(amenity, idx) in amenitiesList"
                      :key="amenity.text"
                    >
                      <div class="row">
                        <div class="col-lg-4 icon-label">
                          <img
                            :src="
                              require(`@/assets/icons/${amenity.iconName}.png`)
                            "
                            width="24"
                            height="24"
                            :alt="amenity.iconAlt"
                          />
                          {{ amenity.text }}
                        </div>
                        <div class="col-lg-4 mt-2">
                          <div class="customSwitcher">
                            <div
                              class="element input-group input-group-outline"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Опис"
                                v-model="amenity.description"
                              />
                            </div>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="amenity.enabled"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row" v-show="amenity.enabled">
                          <div class="col-lg-4"></div>
                          <div class="col-lg-4">
                            <div class="customSwitcherFeatured">
                              <label class="element form-check-label mt-2"
                                >Featured</label
                              >
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  v-model="amenity.featured"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr v-if="idx !== amenitiesList.length - 1" />
                    </div>
                  </div>
                </div>

                <hr v-if="amenityIdx !== Object.keys(amenities).length - 1" />
              </div>
            </div>
          </div>
        </div>

        <!-- Зарплатня -->
        <div
          class="card mt-4"
          id="salary"
          v-if="showDevFeatures && company.crmMode !== 'hotel'"
        >
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-body">
            <h6 class="p-0 mt-0">Зарплатня</h6>
            <div class="row">
              <div class="col">На балансі співробітника:</div>
              <div class="col">
                <label class="text-danger">1732 грн </label>
              </div>
              <div class="col">
                <router-link to="/team/salaryinfostaff/" class="">
                  <i class="fas fa-eye text-secondary"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Зарплатня finish -->

        <!-- Card Delete Account -->
        <div class="card mt-2" id="delete" v-if="this.staffTemplate._id">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h3 v-if="isMobile">Видалити</h3>
                <h6 v-if="isDesktop">Видалити</h6>
                <p class="text-sm mb-0" v-if="company.crmMode !== 'hotel'">
                  {{ getLabelDescriptionRemove() }}
                </p>
                <p class="text-sm mb-0" v-if="company.crmMode === 'hotel'">
                  Коли ви видалите номер, його більше не зможуть забронювати,
                  він не буде відображатись у календарі, але вся історія
                  відвідувань буде збережена в аналітиці.
                </p>
              </div>
              <div class="w-50 text-end">
                <button
                  class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                  type="button"
                  name="button"
                >
                  Відновити
                </button>
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRemoveStaff.modalId}`"
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Delete Account -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  :disabled="!isValidSave()"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти finish-->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveStaff"></BmnCustomPopup>

  <BmnCustomPopup :params="modalParamsImportSyncUrl">
    <template #modal-body>
      <div class="row">
        <div class="col-lg-6">Назва</div>
        <div class="col-lg-6">
          <div class="input-group input-group-outline paymentInput">
            <input
              type="text"
              class="form-control"
              placeholder="Введіть назву"
              v-model="syncUrlImportTemplate.name"
            />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6">URL</div>
        <div class="col-lg-6">
          <div class="input-group input-group-outline paymentInput">
            <input
              type="text"
              class="form-control"
              placeholder="URL"
              v-model="syncUrlImportTemplate.url"
            />
          </div>
        </div>
      </div>
    </template>
  </BmnCustomPopup>
  <BmnCustomPopup :params="modalParamsRemoveSyncUrl"></BmnCustomPopup>

  <BmnCustomPopup :params="modalParamsExportSyncUrl">
    <template #modal-body>
      <div class="row">
        <div class="">
          {{ modalParamsExportSyncUrl.modalDescription }}
        </div>
        <div class="">
          <div class="input-group input-group-outline paymentInput">
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="syncUrlExport"
            />
            <i
              class="material-icons text-lg me-2 cursor-pointer m-3"
              @click="copyToClipboard(syncUrlExport)"
            >
              copy
            </i>
          </div>
        </div>
      </div>
    </template>
  </BmnCustomPopup>
</template>

<script src="./NewStaff.js"></script>

<style scoped>
.available {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
  padding-right: 16px;
}
.element {
  margin-right: 10px;
}
.custom-form-check {
  padding-left: 0px;
}
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.card .card-body {
  padding: 1rem 1rem 1rem 0.5rem;
}
.c-width-200 {
  width: 200px;
}

@media only screen and (max-width: 1488px) {
  .icon-label {
    display: inline-flex;
    align-items: center;
    grid-gap: 15px;
  }
}
th,
td,
tr {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}

.collapse {
  transition: height 0.9s ease;
  overflow: hidden;
  height: 0;
}

.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 12px 12px 12px 12px;
}
.customSwitcher {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
}
.customSwitcherFeatured {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-start;
}

.card {
  border-radius: 0px;
}
</style>
